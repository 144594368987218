@font-face {
  font-family: 'MetaCorrespondence';
  src: url('./assets/fonts/MetaCorrespondence.woff2') format('woff2'), url('./assets/fonts/MetaCorrespondence.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MetaCorrespondence';
  src: url('./assets/fonts/MetaCorrespondence-Bold.woff2') format('woff2'), url('./assets/fonts/MetaCorrespondence-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MetaCorrespondence';
  src: url('./assets/fonts/MetaCorrespondence-Italic.woff2') format('woff2'), url('./assets/fonts/MetaCorrespondence-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

$enable-gradients: true;
$enable-negative-margins: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
  xxxxl: 2135px
) !default;

$blue: #1f3a80;
$gray: #878d90;
$orange: #f28400;

$primary: $blue;
$secondary: $orange;
$info: #dcdcdc;
$dark: $gray;

$h1-font-size: 36px; // 48
$h2-font-size: 24px; // 36
$h3-font-size: 32px; // 32
$h4-font-size: 30px; // 30
$h5-font-size: 30px; // 30
$h6-font-size: 18px; // 24

$paragraph-font-size: 16px;
$paragraph-line-height: 28px;

$display-font-sizes: (
  3: 24px,
  4: 1.0rem,
);

$headings-line-height: 1.2; // 1.0
$headings-font-weight: normal;

$link-color: $blue;
$link-decoration: underline;
$link-font-size: $paragraph-font-size;
$link-hover-color: $orange;
$link-hover-decoration: underline;

$font-family-sans-serif: "MetaCorrespondence", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

h1 {
  hyphens: auto
}

a {
  font-size: $link-font-size;
  transition: all 0.2s ease;
}

p, .p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
}

.border-linie {
  background: $gray;
  height: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
}

blockquote {
  color: $blue;
  border-left: .25em solid $blue;
  padding: 0 0 0 10px;
}

.video-play-button {
  width: 46px;
  height: 46px;
  background: url('/assets/images/play.png');
}

.list-style-primary {
  list-style: square;
  margin:0 0 0 20px;
  padding: 0;
}

.list-style-primary li {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
}

header#menue {
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #dadce0;
  z-index: 50;
}

header#menue nav .logo img {
  cursor: pointer;
  width: 150px;
}

.link {
  color: $blue;
  cursor: pointer;
  transition: all 0.2s ease;
}

.link:hover {
  color: $orange;
}

.unlink {
  cursor: default;
}

.bg-code {
  background: url('/assets/images/bg-code.jpg');
  background-size: contain;
}

#searchbar {
  z-index: 999;
}

@import '~bootstrap/scss/bootstrap.scss';
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }

    .border#{$infix}-top-0 {    border-top: 0 !important; }
    .border#{$infix}-right-0 {  border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 {   border-left: 0 !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

@include media-breakpoint-up(md) {
  
  header#menue nav .logo img {
    width: 200px;
  }

  header#teaser {
    background: #dcdcdc;
  }

}

@include media-breakpoint-up(lg) {

  $h1-font-size: 38px;
  $h2-font-size: 36px;

  $paragraph-font-size: 20px;
  $paragraph-line-height: 34px;

  $link-font-size: $paragraph-font-size;

  h1, .h1 {
    font-size: $h1-font-size;
  }

  h2, .h2 {
    font-size: $h1-font-size;
  }

  p, .p {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
  }

  a, .link {
    font-size: $link-font-size;
    transition: all 0.2s ease;
  }

  header#teaser .row {
    height: 360px;
  }

  .list-style-primary li {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
  }

  .dropdown-heading-item {
    position: relative;
    display: inline-block;
  }

  .dropdown-heading-item:hover .dropdown-content-items {
    display: block;
  }

  .dropdown-content-items {
    display: none;
    position: absolute;
    z-index: 2;
  }

  .dropdown-content-items > span {
    min-width: 200px;
  }
  
  .megamenu-heading-item:hover .megamenu {
    display: block;
  }
  
  .megamenu {
    display: none;
    position: absolute;
    width: 850px;
    background: white;
    z-index: 2;
  }

}

@include media-breakpoint-up(xl) {

  header#teaser .row {
    height: 360px;
  }

}

@media (min-width: 1940px) {

  .bg-code {
    background-size: cover;
  }

  header#teaser .row {
    height: 360px;
  }

}